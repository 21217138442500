import { configureStore } from '@reduxjs/toolkit'
import examSlice from '@/pages/exam/store/examSlice'
import raceSlice from '@/pages/race/store/raceSlice'
import loginSlice from '@/pages/login/store/loginSlice'

export const rootReducer = {
  exam: examSlice,
  race: raceSlice,
  login: loginSlice,
}

export const store = configureStore({
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
