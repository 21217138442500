import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from '@/utils/axios'

interface ProfessionParams {
  name?: string
  current: number
  size: number
}

export interface RaceApplySign {
  applyProfessionId: number
  applyProfessionLevelName: string
  applyProfessionName: string
  cardId: string
  cardImage: string
  cardType: string
  contactAddress: string
  currentProfessionCode: string
  currentProfessionId: string
  currentProfessionImage: string
  currentProfessionLevelName: string
  currentProfessionName: string
  currentProfessionTitle: string
  education: string
  gender: number
  mobile: string
  name: string
  source: string
  status: number
  work: string
  reason?: string
}

export const GetProfessionList = createAsyncThunk(
  'race/getProfessionList',
  async (profession: ProfessionParams) => {
    const { data } = await Axios({
      url: '/profession/page',
      method: 'GET',
      params: profession,
    })
    return data.data
  }
)

export const SumbitRaceApplySign = createAsyncThunk(
  'race/sumbitRaceApplySign',
  async (applySign: RaceApplySign) => {
    const { data } = await Axios({
      url: '/race/apply/sign',
      method: 'POST',
      data: applySign,
    })
    return data
  }
)

export const GetRaceApplyInfo = createAsyncThunk(
  'race/getRaceApplyInfo',
  async (raceId: number) => {
    const { data } = await Axios({
      url: '/race/apply/self/race',
      method: 'GET',
      params: { raceId },
    })
    return data.data
  }
)
