import { Pull } from 'zarm'
import style from './index.module.less'

export enum LOAD_STATE {
  normal = 0, // 普通
  abort = 1, // 中止
  loading = 2, // 加载中
  success = 3, // 加载成功
  failure = 4, // 加载失败
  complete = 5, // 加载完成（无新数据）
}

interface PullLoadProps {
  children?: React.ReactNode
  loadHandler?: () => void
  loading?: number
}

const PullLoad: React.FC<PullLoadProps> = (props) => {
  const { children, loadHandler = () => null, loading } = props

  const loadData = () => {
    if (loadHandler && typeof loadHandler === 'function') {
      loadHandler()
    }
  }

  return (
    <Pull
      className={style.pullLists}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {children}
    </Pull>
  )
}

export default PullLoad
