import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from '@/utils/axios'
import { setPullLoading } from '../store/examSlice'
import { LOAD_STATE } from '@/components/PullLoad'

export interface SubmitExam {
  completeFlag: boolean
  examId: number
  userExamId: number
  userExamPaperSubmits: userExam[]
}

interface userExam {
  paperId: number
  userExamQuestionSubmit: userQuestion[]
}

interface userQuestion {
  answer: string
  questionId: number
}

export interface Paper {
  id: number
  name: string
  score: number
}

interface ExamReply {
  examId: number
  current: number
  size: number
}

export const GetExamDetail = createAsyncThunk(
  'exam/getExamDetail',
  async (examId: number) => {
    const { data } = await Axios({
      url: '/user/exam/start',
      method: 'POST',
      data: {
        examId,
      },
    })
    return data.data
  }
)

export const GetPaperDetail = createAsyncThunk(
  'exam/getPaperDetail',
  async (paperId: number) => {
    const { data } = await Axios({
      url: '/paper/detail',
      method: 'GET',
      params: {
        id: paperId,
      },
    })
    return data.data
  }
)

export const SubmitExam = createAsyncThunk(
  'exam/submitExam',
  async ({
    completeFlag,
    examId,
    userExamId,
    userExamPaperSubmits,
  }: SubmitExam) => {
    const { data } = await Axios({
      url: '/user/exam/submit',
      method: 'POST',
      data: {
        completeFlag,
        examId,
        userExamId,
        userExamPaperSubmits,
      },
    })
    return data.data
  }
)

export const GetExamReuslt = createAsyncThunk(
  'exam/getExamResult',
  async (userExamId: number) => {
    const { data } = await Axios({
      url: '/user/exam/self/result',
      method: 'POST',
      params: {
        id: userExamId,
      },
    })
    return data.data
  }
)

export const GetExamReplyDetail = createAsyncThunk(
  'exam/getExamReplyDetail',
  async (userExamReplyId: number) => {
    const { data } = await Axios({
      url: '/user/exam/reply/self/detail',
      method: 'GET',
      params: { userExamReplyId },
    })
    return data.data
  }
)

export const GetExamReplyPage = createAsyncThunk(
  'exam/getExamReplyPage',
  async ({ current, size, examId }: ExamReply, { dispatch }) => {
    dispatch(setPullLoading(LOAD_STATE.loading))
    const { data } = await Axios({
      url: '/user/exam/reply/page/self',
      method: 'GET',
      params: {
        current,
        size,
        examId,
      },
    })
    if (data.data?.records.length > 0) {
      dispatch(setPullLoading(LOAD_STATE.success))
    } else {
      dispatch(setPullLoading(LOAD_STATE.complete))
    }
    return data.data
  }
)

export const GetExamInfo = createAsyncThunk(
  'exam/getExamInfo',
  async (examId: number, { dispatch }) => {
    const { data } = await Axios({
      url: '/user/exam/self/detail',
      method: 'GET',
      params: { examId },
    })
    data.data?.papers.map((paper: Paper) => dispatch(GetPaperDetail(paper.id)))
    return data.data
  }
)
