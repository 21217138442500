import Cookies from 'js-cookie'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Toast } from 'zarm'

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
})

Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers.token = encodeURIComponent(Cookies.get('token') ?? '')
    return config
  },
  (error) => Promise.reject(error)
)

Axios.interceptors.response.use(
  (resp: AxiosResponse) => {
    if (resp.data.code === 1) {
      Cookies.remove('token')
      Cookies.remove('user')
      Toast.show(resp.data.msg)
      location.hash = '#/login'
    }
    if (resp.data.code !== 0) {
      Toast.show(resp.data.msg)
      return Promise.reject()
    }
    return resp
  },
  (error) => Promise.reject(error)
)

export default Axios
