import React, { lazy } from 'react'

export interface RedirectRoute {
  name: string
  from: string
  to: string
  exact?: boolean
  isRedirect: true
}

export interface NormalRoute {
  name: string
  path: string
  component: React.LazyExoticComponent<React.FC>
  childrens?: NormalRoute[]
  exact?: boolean
  checkLogin?: boolean
  title?: string
}

export type Route = NormalRoute | RedirectRoute

const routes: Route[] = [
  {
    name: '/',
    isRedirect: true,
    from: '/',
    to: '/professional',
    exact: true,
  },
  {
    name: 'exam',
    path: '/exam',
    component: lazy(() => import('@pages/exam')),
    title: '答题',
    checkLogin: true,
    exact: true,
  },
  {
    name: 'examInfo',
    path: '/exam/info',
    component: lazy(() => import('@pages/exam/examInfo')),
    title: '答题记录',
    checkLogin: true,
    exact: true,
  },
  {
    name: 'examReply',
    path: '/exam/reply',
    component: lazy(() => import('@pages/exam')),
    title: '答题详情',
    checkLogin: true,
    exact: true,
  },
  {
    name: 'race',
    path: '/race',
    component: lazy(() => import('@pages/race')),
    title: '培训报名',
    checkLogin: true,
    exact: true,
  },
  {
    name: 'login',
    path: '/login',
    component: lazy(() => import('@pages/login')),
    title: '登录',
    exact: true,
  },
  {
    name: 'reset',
    path: '/reset',
    component: lazy(() => import('@pages/reset')),
    title: '修改密码',
    checkLogin: true,
    exact: true,
  },
  {
    name: 'professional',
    path: '/professional',
    component: lazy(() => import('@pages/professional')),
    title: '职业检索和列表',
    exact: true,
  },
  {
    name: 'professionalDetail',
    path: '/professional/Detail',
    component: lazy(() => import('@pages/professional/detail')),
    title: '职业详情',
    exact: true,
  },
  {
    name: 'service',
    path: '/documents/service',
    component: lazy(() => import('@pages/documents/service')),
    title: '用户服务协议',
    exact: true,
  },
  {
    name: 'privacy',
    path: '/documents/privacy',
    component: lazy(() => import('@pages/documents/privacy')),
    title: '隐私协议',
    exact: true,
  },
  {
    name: 'institutions',
    path: '/applyForInstitutions',
    component: lazy(() => import('@pages/applyForInstitutions')),
    title: '机构申请',
    exact: true,
  },
  {
    name: '404',
    path: '/404',
    component: lazy(() => import('@pages/notFound')),
    exact: true,
  },
  {
    name: 'notFound',
    isRedirect: true,
    from: '/*',
    to: '/404',
    exact: true,
  },
]

export default routes
