import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores'
import {
  GetProfessionList,
  GetRaceApplyInfo,
  RaceApplySign,
  SumbitRaceApplySign,
} from '../service/race'

interface RaceState {
  loading: boolean
  profession: Profession[]
  raceInfo: RaceApplySign | null
  status: number | null
}

interface Profession {
  id: number
  name: string
}

const initialState: RaceState = {
  loading: false,
  raceInfo: null,
  profession: [],
  status: null,
}

const raceSlice = createSlice({
  name: 'race',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
  extraReducers: {
    [GetProfessionList.fulfilled.type]: (
      state,
      action: PayloadAction<{ records: Profession[] }>
    ) => {
      state.profession = action.payload.records
      state.loading = false
    },
    [GetProfessionList.pending.type]: (state) => {
      state.loading = true
    },
    [GetProfessionList.rejected.type]: (state) => {
      state.loading = false
    },
    [SumbitRaceApplySign.fulfilled.type]: (
      state,
      action: PayloadAction<{ code: number }>
    ) => {
      state.status = action.payload.code
      state.loading = false
    },
    [SumbitRaceApplySign.pending.type]: (state) => {
      state.loading = true
    },
    [SumbitRaceApplySign.rejected.type]: (state) => {
      state.loading = false
    },
    [GetRaceApplyInfo.fulfilled.type]: (
      state,
      action: PayloadAction<RaceApplySign>
    ) => {
      state.raceInfo = action.payload
      state.loading = false
    },
    [GetRaceApplyInfo.pending.type]: (state) => {
      state.loading = true
    },
    [GetRaceApplyInfo.rejected.type]: (state) => {
      state.loading = false
    },
  },
})

export const { setLoading } = raceSlice.actions

export const selectLoading = (state: RootState): boolean => state.race.loading
export const selectStatus = (state: RootState): number | null =>
  state.race.status
export const selectRaceInfo = (state: RootState): RaceApplySign | null =>
  state.race.raceInfo
export const selectProfession = (state: RootState): Profession[] =>
  state.race.profession

export default raceSlice.reducer
