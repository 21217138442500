import React, { Suspense, useEffect } from 'react'
import { NavBar, Icon } from 'zarm'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom'
import routes, {
  NormalRoute,
  RedirectRoute,
  Route as RouteProps,
} from '@/routes/route'
import {
  clearUserInfo,
  selectUserInfo,
  setUserInfo,
} from './pages/login/store/loginSlice'

const Nav = () => {
  const location = useLocation()
  const history = useHistory()

  const getTitle = () => {
    const findRoute: RouteProps | undefined = routes.find(
      (route: RouteProps) => {
        return 'path' in route ? route.path === location.pathname : false
      }
    )
    if (!findRoute) {
      return ''
    } else {
      return 'title' in findRoute ? findRoute.title : ''
    }
  }
  return (
    <NavBar
      left={
        <Icon
          type="arrow-left"
          theme="primary"
          onClick={() => history.go(-1)}
        />
      }
      title={getTitle()}
    />
  )
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)

  useEffect(() => {
    if (Cookies.get('user')) {
      if (_.isNull(userInfo)) {
        dispatch(setUserInfo({ user: JSON.parse(Cookies.get('user') ?? '{}') }))
      }
    } else {
      dispatch(clearUserInfo())
    }
  }, [dispatch, userInfo])

  const renderRoutes = (routes: RouteProps[]) => {
    const routesList: React.ReactElement[] = []
    routes.forEach((r: RouteProps) => mapRoute(r, routesList))
    return routesList
  }

  const mapRoute = (route: RouteProps, routesList: React.ReactElement[]) => {
    if ((route as RedirectRoute).isRedirect) {
      routesList.push(
        <Redirect key={route.name} {...(route as RedirectRoute)} />
      )
      return
    }
    routesList.push(renderRoute(route))
    if ((route as NormalRoute)?.childrens?.length) {
      ;(route as NormalRoute)?.childrens?.map((r: RouteProps) =>
        mapRoute(r, routesList)
      )
    }
  }

  const renderRoute = (route: RouteProps) => {
    const { checkLogin } = route as NormalRoute

    return checkLogin ? (
      <CheckLoginRoute key={route.name} {...route} />
    ) : (
      <Route key={route.name} {...route} />
    )
  }

  const CheckLoginRoute = (props: RouteProps) => {
    delete (props as NormalRoute).childrens
    const location = useLocation()
    if (Cookies.get('token')) {
      return <Route key={props.name} {...props} />
    }
    return <Redirect to={{ pathname: '/login', state: location }} />
  }

  return (
    <div className="App">
      <Router>
        <Nav />
        <Suspense fallback={<div>加载中...</div>}>
          <Switch>{renderRoutes(routes)}</Switch>
        </Suspense>
      </Router>
    </div>
  )
}

export default App
