import { LOAD_STATE } from '@/components/PullLoad'
import { RootState } from '@/stores'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  GetExamDetail,
  GetExamInfo,
  GetExamReplyDetail,
  GetExamReplyPage,
  GetExamReuslt,
  GetPaperDetail,
  Paper,
  SubmitExam,
} from '../service/exam'

interface ExamState {
  loading: boolean
  submitExam: boolean
  score: number
  exam: Exam | null
  userExam: UserExam | null
  userExamId: number
  papers: Paper[]
  questions: Questions[]
  examPages: ExamPages[]
  pullLoading: LOAD_STATE
  answerDetail: AnswerDetail[]
}

export interface Exam {
  leftTime: number
  spendTime: number
  model: number
  name: string
}

interface UserExam {
  id: number
}

export interface Questions {
  content: string
  id: number
  title: string
  type: number
  answer: string
  analysis: string
}

interface ExamPages {
  id: number
  examId: number
  score: number
  createTime: number
  startTime: number
  submitTime: number
}

interface AnswerDetail {
  userPapers: UserPapers
  userQuestions: UserQuestions[]
}

interface UserPapers {
  id: number
  paperId: number
  score: number
  userExamId: number
}

interface UserQuestions {
  answer: string
  examId: number
  id: number
  paperId: number
  paperQuestionId: number
  questionId: number
  questionType: PaperQuestionTypeEnum
  score: number
  status: number
  userId: number
  userPaperId: number
}

export enum PaperQuestionTypeEnum {
  Single = 1,
  Multiple = 2,
  Judgment = 3,
  Fill = 4,
  Question = 5,
  SingleBlock = 101,
  MultipleBlock = 102,
  JudgmentBlock = 103,
  FillBlock = 104,
  QuestionBlock = 105,
}

const initialState: ExamState = {
  loading: false,
  score: 0,
  submitExam: false,
  exam: null,
  userExam: null,
  userExamId: 0,
  papers: [],
  questions: [],
  examPages: [],
  pullLoading: LOAD_STATE.normal,
  answerDetail: [],
}

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setPullLoading(state, action) {
      state.pullLoading = action.payload
    },
  },
  extraReducers: {
    [GetExamDetail.fulfilled.type]: (state, action) => {
      state.exam = action.payload.exam
      state.userExam = action.payload.userExam
      state.loading = false
    },
    [GetExamDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamDetail.rejected.type]: (state) => {
      state.loading = false
    },
    [GetPaperDetail.fulfilled.type]: (state, { payload }) => {
      const detail = payload.paperQuestionDetails
      detail.map((p: any) => {
        state.questions.push(...p.questions)
      })
      state.loading = false
    },
    [GetPaperDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetPaperDetail.rejected.type]: (state) => {
      state.loading = false
    },
    [SubmitExam.fulfilled.type]: (state) => {
      state.submitExam = true
      state.loading = false
    },
    [SubmitExam.pending.type]: (state) => {
      state.loading = true
    },
    [SubmitExam.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamReuslt.fulfilled.type]: (state, action) => {
      state.score = _.isNull(action.payload.score) ? 0 : action.payload.score
      state.loading = false
    },
    [GetExamReuslt.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamReuslt.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamReplyPage.fulfilled.type]: (
      state,
      action: PayloadAction<{ current: number; records: ExamPages[] }>
    ) => {
      state.examPages =
        action.payload.current === 1
          ? action.payload.records
          : state.examPages.concat(action.payload.records)
      if (action.payload.current === 1) {
        state.pullLoading = LOAD_STATE.normal
      }
      state.loading = false
    },
    [GetExamReplyPage.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamReplyPage.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamReplyDetail.fulfilled.type]: (
      state,
      action: PayloadAction<
        { userPapers: UserPapers; userQuestions: UserQuestions[] }[]
      >
    ) => {
      state.answerDetail = action.payload
      // TODO: 暂时取第一个
      state.userExamId = action.payload[0].userPapers.userExamId
      state.loading = false
    },
    [GetExamReplyDetail.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamReplyDetail.rejected.type]: (state) => {
      state.loading = false
    },
    [GetExamInfo.fulfilled.type]: (
      state,
      action: PayloadAction<{ exam: Exam; papers: Paper[]; userExam: UserExam }>
    ) => {
      state.loading = false
      state.exam = action.payload.exam
      state.papers = action.payload.papers
      state.userExam = action.payload.userExam
      state.userExamId = action.payload.userExam.id
    },
    [GetExamInfo.pending.type]: (state) => {
      state.loading = true
    },
    [GetExamInfo.rejected.type]: (state) => {
      state.loading = false
    },
  },
})

export const { setLoading, setPullLoading } = examSlice.actions

export const selectLoading = (state: RootState): boolean => state.exam.loading
export const selectScore = (state: RootState): number => state.exam.score
export const selectExam = (state: RootState): Exam => state.exam.exam as Exam
export const selectUserExam = (state: RootState): UserExam =>
  state.exam.userExam as UserExam
export const selectUserExamId = (state: RootState): number =>
  state.exam.userExamId
export const selectPapers = (state: RootState): Paper[] => state.exam.papers
export const selectQuestions = (state: RootState): Questions[] =>
  state.exam.questions
export const selectSubmitExam = (state: RootState): boolean =>
  state.exam.submitExam
export const selectExamPages = (state: RootState): ExamPages[] =>
  state.exam.examPages
export const selectPullLoading = (state: RootState): LOAD_STATE =>
  state.exam.pullLoading
export const selectAnswerDetail = (state: RootState): AnswerDetail[] =>
  state.exam.answerDetail

export default examSlice.reducer
