import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores'

interface LoginState {
  isLogin: boolean
  userInfo: User | null
}

interface User {
  id: number
  gender: number
  headImage: string
  mobile: number
  name: string
}

const initialState: LoginState = {
  isLogin: false,
  userInfo: null,
}

const loginSlice = createSlice({
  name: 'race',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<{ user: User }>) {
      state.userInfo = action.payload.user
      state.isLogin = true
    },
    clearUserInfo: (state) => {
      state.isLogin = false
      state.userInfo = null
    },
  },
})

export const { setUserInfo, clearUserInfo } = loginSlice.actions

export const selectIsLogin = (state: RootState): boolean => state.login.isLogin
export const selectUserInfo = (state: RootState): User | null =>
  state.login.userInfo

export default loginSlice.reducer
